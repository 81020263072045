import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PDFViewer from "../Misc/PDFViewer";
import VideoPlayer from "../Misc/VideoPlayer";
import MiscFunctions from "../../helpers/MiscFunctions";

class CommentFileMiniSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: this.props.fileIndex,
            activeSlide: this.props.clickedComment !== undefined && props.clickedComment.medias[props.fileIndex],
            slides: props.clickedComment !== undefined && props.clickedComment.medias,
            pause: false,
        };
    }

    setVideoPause = (bool) => {
        this.setState({ pause: bool })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clickedComment !== this.props.clickedComment) {
            this.setState({ slides: this.props.clickedComment.medias });
        }
    }

    goToPrevSlide = () => {
        const { activeIndex, slides } = this.state;
        const newIndex = activeIndex > 0 ? activeIndex - 1 : slides.length - 1;
        this.setActiveItem(newIndex, slides[newIndex]);
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
    };

    goToNextSlide = () => {
        const { activeIndex, slides } = this.state;
        const newIndex = activeIndex < slides.length - 1 ? activeIndex + 1 : 0;
        this.setActiveItem(newIndex, slides[newIndex]);
        this.props.setFileIndex(newIndex);
        this.setVideoPause(true);
    };

    setActiveItem = (index, slide) => {
        this.setState({ activeIndex: index, activeSlide: slide });
    };

    updateMedias() {
        if (!MiscFunctions.isNull(this.state.slides)) {
            const data = this.state.slides.filter(
                (slide) =>
                    slide.description &&
                    !slide.description.trim().toLowerCase().includes("deleted media".toLowerCase())
            );
            this.setState({ slides: data });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.clickedComment !== this.props.clickedComment) {
            this.updateMedias();
        }
    }
    
    componentDidMount() {
        this.updateMedias();
    }
    
    render() {
        const { activeIndex, slides } = this.state;
        return (
            this.state.activeSlide !== undefined && <div className="mini-slider-block">
                <div className="mini-slider-header">
                    <span className="back-btn-filename-block">
                        <span
                            className="mini-slider-back-btn"
                            onClick={() => this.props.setMiniSlider(false)}
                        ></span>
                        <span className="mini-slider-file-title">
                            {
                                !MiscFunctions.isNull(this.state.slides[this.state.activeIndex].filename) ? this.state.slides[this.state.activeIndex].filename : this.state.slides[this.state.activeIndex].description
                            }
                        </span>
                    </span>
                        <span className="icon-download-small" onClick={() => MiscFunctions.handleDownload(this.state.slides[this.state.activeIndex])}></span>
                </div>

                <div className="mini-slider">
                    <div className="mini-slider-slides">
                        {slides !== undefined &&
                            slides.map((slide, index) => (
                                <div
                                    key={slide.id}
                                    className={`mini-slider-slide ${index === activeIndex ? "active" : ""
                                        }`}
                                    style={{ display: index === activeIndex ? "block" : "none" }}
                                >
                                    {slide.type === "picture" && (
                                        <img src={slide.url} alt={slide.description} />
                                    )}
                                    {slide.type === "audio" && <audio src={slide.url} controls />}
                                    {slide.type === "video" && (
                                        <VideoPlayer
                                            url={slide.url}
                                            width="468px"
                                            height="265px"
                                            size="small"
                                            setVideoPause={this.setVideoPause}
                                            pause={this.state.pause}
                                        />
                                    )}
                                    {slide.type === "document" && (
                                        <PDFViewer
                                            fileUrl={slide.url}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className="mini-slider-prev-next-block">
                        <button
                            className="mini-slider-prev"
                            onClick={this.goToPrevSlide}
                            disabled={
                                slides !== undefined && slides.length > 1 ? false : true
                            }
                        >
                            <span className="mini-slider-prev-icon"></span>
                        </button>

                        <div className="mini-slider-indicators">
                            {slides !== undefined &&
                                slides.map((slide, index) => (
                                    <span
                                        key={slide.id}
                                        className={`mini-slider-indicator ${index === activeIndex ? "active" : ""
                                            }`}
                                        onClick={() => this.setActiveItem(index, slide)}
                                    ></span>
                                ))}
                        </div>

                        <button
                            className="mini-slider-next"
                            onClick={this.goToNextSlide}
                            disabled={
                                slides !== undefined && slides.length > 1 ? false : true
                            }
                        >
                            <span className="mini-slider-next-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CommentFileMiniSlider);
