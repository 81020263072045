import React, { Children, Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import CommentDetials from "./CommentDetials";
import CommentAction from "./CommentAction";
import CommentFooter from "./CommentFooter";
import Comment from "../../services/CommentServices";
import CommentFileMiniSlider from "./CommentFileMiniSlider";
import CommentFileFullSlider from "./CommentFileFullSlider";
import { Spinner } from "flowbite-react";
import commentAPI from "../../api/commentAPI";
import MiscFunctions from "../../helpers/MiscFunctions";

class CommentDisplayContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initSide: null,
      switchSide: null,
      topBottomPosition: "top",
      topPosition: false,
      commentAnnotationContainerWidth:
        this.props.commentAnnotationContainerWidth,
      miniSlider: false,
      fullSlider: false,
      fileIndex: null,
      clickedComment: this.props.clickedComment,
      commentReplies: null,
      showReplyBox: false,
      actionBtnHeight: "auto",
      actionBtnReplyHeight: "auto",
      saveButtonClick: null,
      saveError: false,
      checkCommentIsReply: false,
      commentMedias: [],
      changeCommentHeight: false,
      mainCommentActionPosition: null,
    };
    this.commentContentFooterRef = React.createRef();
    this.commentReplyBoxRef = React.createRef();
  }
  changeValue = (value) => {
    this.setState({changeCommentHeight: value})
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.changeCommentHeight !== this.state.changeCommentHeight){
      const contentReplyHeight = this.commentContentFooterRef.current.offsetHeight;
      this.setState({ mainCommentActionPosition: contentReplyHeight,
    });
    }

    if (prevProps.switchSide !== this.props.switchSide) {
      this.setState({ switchSide: this.props.switchSide });
    }

    if (
      prevProps.commentAnnotationContainerWidth !==
      this.props.commentAnnotationContainerWidth
    ) {
      if (
        this.props.commentAnnotationContainerWidth < 1000 &&
        parseFloat(this.props.commentBoxAnnotation.left) < 70
      )
        this.setState({ switchSide: "left" });
      else this.setState({ switchSide: this.state.initSide });
    }
    if (prevProps.clickedComment !== this.props.clickedComment) {
      this.setState({ clickedComment: this.props.clickedComment });
    }
    if (prevProps.commentUpdated !== this.props.commentUpdated) {
      const clickedComment = new Comment(this.state.clickedComment);
      const childs = commentAPI.getCommentChildrenById(clickedComment.getID);
      childs.then((res) => this.setState({ commentReplies: res }));
    }

    if(prevProps.commentReply !== this.props.commentReply){
      const clickedComment = new Comment(this.state.clickedComment);
      const childs = commentAPI.getCommentChildrenById(clickedComment.getID);
      childs.then((res) => this.setState({ commentReplies: res }));
    }

    if(prevProps.editCommentReply !== this.props.editCommentReply){
      const clickedComment = new Comment(this.state.clickedComment);
      const childs = commentAPI.getCommentChildrenById(clickedComment.getID);
      childs.then((res) => this.setState({ commentReplies: res }));
    }

    if(prevState.saveButtonClick !== this.state.saveButtonClick){
      const clickedComment = new Comment(this.state.clickedComment);
      const childs = commentAPI.getCommentChildrenById(clickedComment.getID);
      childs.then((res) => {this.setState({ commentReplies: res })
    });
    }

    if(prevProps.commentReply !== this.props.commentReply){
      this.setState({commentMedias: []});
    }

    if(prevProps.editCommentReply !== this.props.editCommentReply){
      this.setState({commentMedias: this.props.commentForReply.medias});
    }
  }
  componentDidMount() {
    if(this.commentContentFooterRef.current){
      const contentReplyHeight = this.commentContentFooterRef.current.offsetHeight;
      this.setState({ mainCommentActionPosition: contentReplyHeight,
    });
    }
    if (this.commentContentFooterRef.current) {
      const contentAndfooterHeight =
        this.commentContentFooterRef.current.offsetHeight;
      this.setState({ actionBtnHeight: contentAndfooterHeight });
    }
    if (this.commentReplyBoxRef.current) {
      const contentReplyHeight = this.commentReplyBoxRef.current.offsetHeight;
      this.setState({ actionBtnReplyHeight: contentReplyHeight });
    }
    const { commentBoxAnnotation } = this.props;

    // Check if comment is added close to top then return true/false
    const topPositionValue =
      commentBoxAnnotation &&
        (parseFloat(commentBoxAnnotation.top) < 10|| parseFloat(commentBoxAnnotation.top) > 70)
        ? true
        : false;
    const sideValue =
      commentBoxAnnotation && parseFloat(commentBoxAnnotation.left) > 55
        ? "right"
        : "left";
    const topBottomValue =
      commentBoxAnnotation &&
        parseFloat(this.props.commentBoxAnnotation.top) > 70
        // ? "bottom"
        ? "center"
        : "top";
    this.setState({
      initSide: sideValue,
      switchSide: sideValue,
      topBottomPosition: topBottomValue,
      topPosition: topPositionValue,
    });

    if (
      this.props.commentAnnotationContainerWidth < 1000 &&
      parseFloat(commentBoxAnnotation.left) < 70
    ) {
      this.setState({ switchSide: "left" });
    }
    const clickedComment = new Comment(this.state.clickedComment);
    const childs = commentAPI.getCommentChildrenById(clickedComment.getID);
    childs.then((res) => this.setState({ commentReplies: res }));
  }

  setMiniSlider = (status) => {
    this.setState({
      miniSlider: status,
    });
  };

  setFileIndex = (index) => {
    this.setState({
      fileIndex: index,
    });
  };
  setFullSlider = (status) => {
    this.setState({
      fullSlider: status,
    });
  };
  toggleReplies() {
    this.setState({ showReplyBox: !this.state.showReplyBox });
  }
  handleSaveButonClick = (event) => {
    this.setState({ saveButtonClick: event });
    this.props.setCommentLoad(true);
  };
  setSaveError = (status) => {
    if (status === true) this.props.setCommentLoad(false);
    this.setState({ saveError: status });
  };
  
  addToMedias = (mediaObject) => {
    this.setState((prevState) => ({
      commentMedias: [...prevState.commentMedias, mediaObject],
    }));
  };
  render() {
    const clickedComment = new Comment(this.state.clickedComment);
    this.props.passVideoCaption.find((o) => {
      if (o.id === clickedComment.getID) return true;
      else return false;
    });

    const {
      switchSide,
      topBottomPosition,
      topPosition,
      miniSlider,
      fullSlider,
      commentReplies,
      showReplyBox,
    } = this.state;
    return (
      <div
        className="comment-display-container"
        style={{
          left: this.props.commentBoxAnnotation.left,
          top: this.props.commentBoxAnnotation.top,
        }}
      >
        <div className={`comment-anchor-${switchSide}`}>
          <span
            className={`location-icon-${switchSide} ${clickedComment.category &&
              clickedComment.category[0].type + "-location-color"
              } ${topPosition
                ? `location-icon-${switchSide}-${topBottomPosition}`
                : ``
              }`}
            onClick={() => {
              this.props.closeDislayComment();
            }}
          ></span>
        </div>
        <div className={topPosition ? `main-comment-action-${topBottomPosition}-${switchSide}` : `main-comment-action-center-${switchSide}`}>
          {this.props.commentLoad && (
            <div
              className={`comment-loader comment-content-block comment-content-block-${switchSide} ${topPosition
                ? `comment-content-block-${topBottomPosition}-${switchSide}`
                : ``
                }`}
            >
              <Spinner
                color="success"
                aria-label="loading comments"
                className="loading-comment-card"
                size="lg"
              />
            </div>
          )}
          <div
            className={`comment-content-block comment-content-block-${switchSide} ${topPosition
              ? `comment-content-block-${topBottomPosition}-${switchSide}`
              : ``
              }`}
          >
            <div
              className={`${clickedComment.category &&
                clickedComment.category[0].icon +
                "-" +
                switchSide +
                "-border-color"
                } ${MiscFunctions.isNull(commentReplies)||MiscFunctions.isUndefined(commentReplies) ? `comment-content-block-${switchSide}-border-without-reply` : `comment-content-block-${switchSide}-border` } `}
            >
              {clickedComment && !miniSlider && (
                <>
                  <div
                    className="comment-content-footer"
                    ref={this.commentContentFooterRef}
                    style={{
                      borderRadius: `${!MiscFunctions.isNull(switchSide) &&
                        !MiscFunctions.isUndefined(switchSide) &&
                        switchSide === "left"
                        ? "0 5px 5px 0"
                        : "5px 0 0 5px"
                        }`,
                    }}
                  >
                    <CommentDetials
                      getClickedComment={this.props.getClickedComment}
                      clickedComment={clickedComment}
                      commentDisplay={true}
                      replyComment={true}
                      switchSide={switchSide}
                      setToastMessage={this.props.setToastMessage}
                      setUpdateMediaFile={this.props.setUpdateMediaFile}
                      updateMediaFile={this.props.updateMediaFile}
                      setSaveError={this.setSaveError}
                      changeCommentHeight={this.state.changeCommentHeight}
                      changeValue={this.changeValue}
                    />
                    <CommentFooter
                      clickedComment={clickedComment}
                      deleteComment={this.props.deleteComment}
                      setDeleteComment={this.props.setDeleteComment}
                      setMiniSlider={this.setMiniSlider}
                      commentDisplay={true}
                      user={this.props.user}
                      closeDislayComment={this.props.closeDislayComment}
                      setFileIndex={this.setFileIndex}
                      closeDisplayComment={this.props.closeDisplayComment}
                      deteleCommentId={this.props.deleteCommentId}
                      setDeleteCommentId={this.props.setDeleteCommentId}
                      setUpdateMediaFile={this.props.setUpdateMediaFile}
                      updateMediaFile={this.props.updateMediaFile}
                      switchSide={switchSide}
                      changeCommentHeight={this.state.changeCommentHeight}
                      changeValue={this.changeValue}
                    />
                  </div>
                  {!MiscFunctions.isNull(commentReplies) &&
                    !MiscFunctions.isUndefined(commentReplies) && (
                      <div className={`comment-reply-block-${switchSide}`}>
                        <span className="comment-reply-number">
                          {commentReplies.length} Number of replies
                        </span>
                        <span
                          className={`${showReplyBox
                            ? "drop_down_arrow_reply_open"
                            : "drop_down_arrow_reply"
                            }`}
                          onClick={() => this.toggleReplies()}
                        ></span>
                      </div>
                    )}
                </>
              )}
              {miniSlider && (
                <>
                  <CommentFileMiniSlider
                    clickedComment={clickedComment}
                    setMiniSlider={this.setMiniSlider}
                    fileIndex={this.state.fileIndex}
                    setFileIndex={this.setFileIndex}
                  />
                </>
              )}
              {!miniSlider && fullSlider && (
                <CommentFileFullSlider
                  clickedComment={clickedComment}
                  setFullSlider={this.setFullSlider}
                  fileIndex={this.state.fileIndex}
                />
              )}
            </div>
              <div
                className={`comment-reply-card comment-reply-card-${switchSide} ${switchSide === "right" ? `comment-reply-content-block` : ``} comment-reply-content-block-${topBottomPosition}-${switchSide} ${clickedComment.category &&
                  clickedComment.category[0].icon +
                  "-" +
                  switchSide +
                  "-border-color"
                  } `}
              >
                {this.props.commentReply && (
                  <div className={` ${switchSide==="left" ? `comment-reply-main-block comment-reply-main-block-left` : `comment-reply-main-block`} ${clickedComment.category &&
                  clickedComment.category[0].icon +
                  "-" +
                  switchSide +
                  "-border-color"
                  }`}>
                   <div className={`${switchSide==="right" ? `comment-reply-border` : ``} ${clickedComment.category &&
                          clickedComment.category[0].icon +
                          "-" +
                          switchSide +
                          "-border-color"
                          }`}>
                    <div
                      className={`comment-reply-box ${clickedComment.category &&
                        clickedComment.category[0].icon +
                        "-" +
                        switchSide +
                        "-border-color"
                        }`}
                      ref={this.commentReplyBoxRef}
                      style={{
                        borderRadius: `${!MiscFunctions.isNull(switchSide) &&
                          !MiscFunctions.isUndefined(switchSide) &&
                          switchSide === "left"
                          ? "0px 5px 5px 0px"
                          : "5px 0 0 5px"
                          }`,
                      }}
                    >
                      <CommentDetials
                        setToastMessage={this.props.setToastMessage}
                        getClickedComment={this.props.getClickedComment}
                        clickedComment={this.props.commentForReply}
                        commentReply={this.props.commentReply}
                        switchSide={switchSide}
                        user={this.props.user}
                        setChangesMade={this.props.setChangesMade}
                        saveButtonClick={this.state.saveButtonClick}
                        setSaveError={this.setSaveError}
                        closeCommentReply={this.props.closeCommentReply}
                        setAddComment={this.props.setAddComment}
                        commentUpdated={this.props.commentUpdated}
                        setCommentUpdated={this.props.setCommentUpdated}
                        setUpdateMediaFile={this.props.setUpdateMediaFile}
                        updateMediaFile={this.props.updateMediaFile}
                        commentMedias={this.state.commentMedias}
                        companyUsers={this.props.companyUsers}
                        changeValue={this.changeValue}
                      />
                      <CommentFooter
                        commentReply={this.props.commentReply}
                        clickedComment={this.props.commentForReply}
                        deleteComment={this.props.deleteComment}
                        setDeleteComment={this.props.setDeleteComment}
                        setMiniSlider={this.setMiniSlider}
                        user={this.props.user}
                        closeDislayComment={this.props.closeDislayComment}
                        setFileIndex={this.setFileIndex}
                        deteleCommentId={this.props.deleteCommentId}
                        setDeleteCommentId={this.props.setDeleteCommentId}
                        setUpdateMediaFile={this.props.setUpdateMediaFile}
                        updateMediaFile={this.props.updateMediaFile}
                        switchSide={switchSide}
                        addToMedias={this.addToMedias}
                        commentMedias={this.state.commentMedias}
                        commentReplyEdit={true}
                        changeCommentHeight={this.state.changeCommentHeight}
                        changeValue={this.changeValue}
                      />
                    </div>
                    </div>
                    <div className={`${topPosition
                ? `comment-reply-action-${topBottomPosition}-${switchSide}`
                : `comment-reply-action-center-${switchSide}`
              }`}>
                    {!this.props.commentLoad && switchSide === "right" && (
                      <CommentAction
                        switchSide={switchSide}
                        left={this.props.commentBoxAnnotation.left}
                        top={this.props.commentBoxAnnotation.top}
                        topPosition={topPosition}
                        topBottomPosition={topBottomPosition}
                        miniSlider={miniSlider}
                        setMiniSlider={this.setMiniSlider}
                        setFullSlider={this.setFullSlider}
                        handlecommentUpdate={this.props.handlecommentUpdate}
                        handleCommentReply={this.props.handleCommentReply}
                        handleEditCommentReply={this.props.handleEditCommentReply}
                        clickedComment={this.props.commentForReply}
                        user={this.props.user}
                        actionBtnHeight={this.state.actionBtnReplyHeight}
                        commentReply={this.props.commentReply}
                        closeCommentReply={this.props.closeCommentReply}
                        handleSaveButonClick={this.handleSaveButonClick}
                        checkCommentIsReply={false}
                        saveError={this.state.saveError}
                        setSaveError={this.setSaveError}
                      />
                    )}
                    {!this.props.commentLoad && switchSide === "left" && (
                      <CommentAction
                        switchSide={switchSide}
                        left={this.props.commentBoxAnnotation.left}
                        top={this.props.commentBoxAnnotation.top}
                        topPosition={topPosition}
                        topBottomPosition={topBottomPosition}
                        miniSlider={miniSlider}
                        setMiniSlider={this.setMiniSlider}
                        setFullSlider={this.setFullSlider}
                        handlecommentUpdate={this.props.handlecommentUpdate}
                        handleCommentReply={this.props.handleCommentReply}
                        handleEditCommentReply={this.props.handleEditCommentReply}
                        clickedComment={this.props.commentForReply}
                        user={this.props.user}
                        actionBtnHeight={this.state.actionBtnReplyHeight}
                        commentReply={this.props.commentReply}
                        closeCommentReply={this.props.closeCommentReply}
                        handleSaveButonClick={this.handleSaveButonClick}
                        checkCommentIsReply={false}
                        saveError={this.state.saveError}
                        setSaveError={this.setSaveError}
                      />
                    )}
                    </div>
                  </div>
                )}
                {showReplyBox &&
                  !MiscFunctions.isNull(commentReplies) &&
                  commentReplies.map((reply, index) => {
                    const comment = new Comment(reply);
                    return (
                      clickedComment.getID === reply.parent.id && (
                        <div className={`${switchSide==="left" ? `comment-reply-main-block comment-reply-main-block-left` : `comment-reply-main-block`} ${clickedComment.category &&
                          clickedComment.category[0].icon +
                          "-" +
                          switchSide +
                          "-border-color"
                          }`}>
                          <div className={`${switchSide==="right" ? commentReplies.length-1 === index ? `comment-reply-border-last`: `comment-reply-border` : ``} ${clickedComment.category &&
                          clickedComment.category[0].icon +
                          "-" +
                          switchSide +
                          "-border-color"
                          }`}>
                            <div
                              className={`comment-reply-box ${clickedComment.category &&
                                clickedComment.category[0].icon +
                                "-" +
                                switchSide +
                                "-border-color"
                                }`}
                              ref={this.commentReplyBoxRef}
                              style={{
                                borderRadius: `${!MiscFunctions.isNull(switchSide) &&
                                  !MiscFunctions.isUndefined(switchSide) &&
                                  switchSide === "left"
                                  ? "0px 5px 5px 0px"
                                  : "5px 0 0 5px"
                                  }`,
                              }}
                            >
                              <CommentDetials
                                getClickedComment={this.props.getClickedComment}
                                clickedComment={comment}
                                commentDisplay={true}
                                switchSide={switchSide}
                                user={this.props.user}
                                saveButtonClick={this.state.saveButtonClick}
                                setChangesMade={this.props.setChangesMade}
                                checkCommentIsReply={true}
                                setToastMessage={this.props.setToastMessage}
                                setUpdateMediaFile={this.props.setUpdateMediaFile}
                                updateMediaFile={this.props.updateMediaFile}
                                setSaveError={this.setSaveError}
                                editCommentReply={this.props.editCommentReply}
                                commentForReply={this.props.commentForReply}
                                setCommentUpdated={this.props.setCommentUpdated}
                                closeCommentReplyEdit={this.props.closeCommentReplyEdit}
                                setAddComment={this.props.setAddComment}
                                commentMedias={this.state.commentMedias}
                                companyUsers={this.props.companyUsers}
                                getCommentForReply={this.props.getCommentForReply}
                                changeValue={this.changeValue}
                              />
                              <CommentFooter
                                clickedComment={comment}
                                deleteComment={this.props.deleteComment}
                                setDeleteComment={this.props.setDeleteComment}
                                setMiniSlider={this.setMiniSlider}
                                commentDisplay={true}
                                user={this.props.user}
                                closeDislayComment={this.props.closeDislayComment}
                                setFileIndex={this.setFileIndex}
                                closeDisplayComment={this.props.closeDisplayComment}
                                deteleCommentId={this.props.deleteCommentId}
                                setDeleteCommentId={this.props.setDeleteCommentId}
                                setUpdateMediaFile={this.props.setUpdateMediaFile}
                                updateMediaFile={this.props.updateMediaFile}
                                switchSide={switchSide}
                                checkCommentIsReply={true}
                                editCommentReply={this.props.editCommentReply}
                                addToMedias={this.addToMedias}
                                commentMedias={this.state.commentMedias}
                                commentEdit={this.props.commentEdit}
                                commentForReply={this.props.commentForReply}
                                changeCommentHeight={this.state.changeCommentHeight}
                                changeValue={this.changeValue}
                              />
                            </div>
                          </div>
                          <div className={`${topPosition
                ? `comment-reply-action-${topBottomPosition}-${switchSide}`
                : `comment-reply-action-center-${switchSide}`
              }`}>
                            {!this.props.commentLoad && switchSide === "right" && (
                              <CommentAction
                                commentDisplay={true}
                                switchSide={switchSide}
                                left={this.props.commentBoxAnnotation.left}
                                top={this.props.commentBoxAnnotation.top}
                                topPosition={topPosition}
                                topBottomPosition={topBottomPosition}
                                miniSlider={miniSlider}
                                setMiniSlider={this.setMiniSlider}
                                setFullSlider={this.setFullSlider}
                                handlecommentUpdate={this.props.handlecommentUpdate}
                                handleCommentReply={this.props.handleCommentReply}
                                handleEditCommentReply={this.props.handleEditCommentReply}
                                clickedComment={comment}
                                user={this.props.user}
                                actionBtnHeight={this.state.actionBtnReplyHeight}
                                commentReply={this.props.commentReply}
                                checkCommentIsReply={true}
                                editCommentReply={this.props.editCommentReply}
                                commentEdit={this.props.commentEdit}
                                closeCommentReplyEdit={this.props.closeCommentReplyEdit}
                                handleSaveButonClick={this.handleSaveButonClick}
                                commentForReply={this.props.commentForReply}
                                saveError={this.state.saveError}
                                setSaveError={this.setSaveError}
                              />
                            )}
                            {!this.props.commentLoad && switchSide === "left" && (
                              <CommentAction
                                commentDisplay={true}
                                switchSide={switchSide}
                                left={this.props.commentBoxAnnotation.left}
                                top={this.props.commentBoxAnnotation.top}
                                topPosition={topPosition}
                                topBottomPosition={topBottomPosition}
                                miniSlider={miniSlider}
                                setMiniSlider={this.setMiniSlider}
                                setFullSlider={this.setFullSlider}
                                handlecommentUpdate={this.props.handlecommentUpdate}
                                handleCommentReply={this.props.handleCommentReply}
                                handleEditCommentReply={this.props.handleEditCommentReply}
                                clickedComment={comment}
                                user={this.props.user}
                                actionBtnHeight={this.state.actionBtnReplyHeight}
                                checkCommentIsReply={true}
                                editCommentReply={this.props.editCommentReply}
                                commentEdit={this.props.commentEdit}
                                closeCommentReplyEdit={this.props.closeCommentReplyEdit}
                                handleSaveButonClick={this.handleSaveButonClick}
                                commentForReply={this.props.commentForReply}
                                saveError={this.state.saveError}
                                setSaveError={this.setSaveError}
                              />
                            )}
                          </div>
                        </div>
                      )
                    );
                  })}
              </div>
          </div>
          {!this.props.commentLoad && <CommentAction
            commentDisplay={true}
            switchSide={switchSide}
            left={this.props.commentBoxAnnotation.left}
            top={this.props.commentBoxAnnotation.top}
            topPosition={topPosition}
            topBottomPosition={topBottomPosition}
            miniSlider={miniSlider}
            setMiniSlider={this.setMiniSlider}
            setFullSlider={this.setFullSlider}
            handlecommentUpdate={this.props.handlecommentUpdate}
            handleCommentReply={this.props.handleCommentReply}
            handleEditCommentReply={this.props.handleEditCommentReply}
            clickedComment={clickedComment}
            user={this.props.user}
            actionBtnHeight={this.state.actionBtnHeight}
            changeCommentHeight={this.state.changeCommentHeight}
            commentReply={this.props.commentReply}
            closeCommentReply={this.props.closeCommentReply}
            commentForReply={this.props.commentForReply}
            mainCommentActionPosition={this.state.mainCommentActionPosition}
          />}
        </div>
      </div>
    );
  }
}
export default withTranslation()(CommentDisplayContainer);
